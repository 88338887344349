import Multipayment from "./Multipayment";

$(() => {
  const getInputElementById = (elementId: string) => {
    return <HTMLInputElement>document.getElementById(elementId);
  };

  $('.js-close-modal').on('click', (e: JQuery.Event) => {
    e.preventDefault()
    const modal: any = $('#js-creditCardModal');
    modal.modal('hide');
  });
  $('.js-close-cancel-modal').on('click', (e: JQuery.Event) => {
    e.preventDefault()
    const modal: any = $('#js-cancelCreditCardModal');
    modal.modal('hide');
  });

  $('.js-register-credit-card').on('click', (e: JQuery.Event) => {
    e.preventDefault();
    const mp: Multipayment = eval("Multipayment");

    const getShopId = function () {
      return getInputElementById('gmo-shop-id').value;
    }
    const getCreditCardNumber = function () {
      const cardNumber = getInputElementById('input-creditcard-number').value;
      return cardNumber.replace(/-/g, '');
    };
    const getExpire = function () {
      const year = getInputElementById('date_year').value;
      const twoDigitYear = year.slice(-2);
      const rawMonth = getInputElementById('date_month').value;
      const zeroMonth = ('0' + rawMonth).slice(-2);
      return `${twoDigitYear}${zeroMonth}`;
    };
    const getSecurityCode = function () {
      return getInputElementById('input-security-code').value;
    };
    const showErrorMessage = function (targetInputId, message) {
      const targetInput = document.getElementById(targetInputId);
      targetInput.classList.add('is-invalid');
      targetInput.insertAdjacentHTML('afterend', `<p class="invalid-feedback">${message}</p>`);
    };
    const clearErrorMessage = function () {
      const modal = document.getElementById('js-creditCardModal');
      modal.querySelectorAll('.is-invalid').forEach(
        (element, _i, _a) => { element.classList.remove('.is-invalid') }
      );
      modal.querySelectorAll('.invalid-feedback').forEach(
        (element, _i, _a) => { element.remove() }
      );
    };
    const validateCreditCardInformation = function (creditCardNumber, _expire, securityCode) {
      clearErrorMessage();

      const errors = [];
      const creditCardElementName = 'input-creditcard-number';
      if (creditCardNumber) {
        if (!creditCardNumber.match(/^[0-9\-]+$/)) {
          errors.push([creditCardElementName, 'カード番号は数字と「-」のみで入力してください']);
        } else if (!creditCardNumber.replace(/-/g, '').match(/^[0-9]{14,16}$/)) {
          errors.push([creditCardElementName, 'カード番号の桁数が誤っています']);
        }
      } else {
        errors.push([creditCardElementName, 'カード番号を入力してください']);
      }
      const securityCodeElementName = 'input-security-code';
      if (securityCode) {
        if (!securityCode.match(/^[0-9]{3,4}$/)) {
          errors.push([securityCodeElementName, 'セキュリティーコードは数字で3桁、または4桁で入力してください']);
        }
      } else {
        errors.push([securityCodeElementName, 'セキュリティーコードを入力してください']);
      }
      // NOTE: 期限(expire)はselectで最初から値が入っているためチェックしない。
      //       ユーザーの利便性の向上に寄与せず、改ざん対策にはJSのvalidationは意味がないので。

      if (errors.length == 0) {
        return true;
      } else {
        errors.forEach(function (val, index, errs) {
          showErrorMessage(val[0], val[1]);
        });
        return false;
      }
    };
    const setValue = function (inputId, value) {
      getInputElementById(inputId).value = value;
    };
    const strIns = function (str, idx, val) {
      var res = str.slice(0, idx) + val + str.slice(idx);
      return res;
    }
    const creditCardBrand = {
      'visa': 'Visa',
      'mastercard': 'Mastercard',
      'jcb': 'JCB',
      'diners': 'DinersClub',
      'amex': 'AmericanExpress'
    }
    const findCreditBrand = function (creditCardNumber) {
      // クレジット番号からクレジット会社を導出するための数字(桁)
      // [詳細] https://www.notion.so/emerada/930df49bfce04b8cad09eeade1e45a05?pvs=4#106c87425e874be592913c17680f820f
      if (/^4/.test(creditCardNumber)) {
        return creditCardBrand['visa'];
      } else if (/^5/.test(creditCardNumber)) {
        return creditCardBrand['mastercard'];
      } else if (/^35/.test(creditCardNumber)) {
        return creditCardBrand['jcb'];
      } else if (/^36/.test(creditCardNumber)) {
        return creditCardBrand['diners'];
      } else if (/^(34|37)/.test(creditCardNumber)) {
        return creditCardBrand['amex'];
      }
    }

    const buildCreditIconDom = function(creditBrand: string) {
      let creditBrandIcon = null;
      switch(creditBrand) {
        case creditCardBrand['visa']:
          creditBrandIcon = '<div class="m-1 d-InvoiceIndex-creditCardLog--visa"></div>';
          break;
        case creditCardBrand['mastercard']:
          creditBrandIcon = '<div class="m-1 d-InvoiceIndex-creditCardLog--mastercard"></div>';
          break;
        case creditCardBrand['jcb']:
          creditBrandIcon = '<div class="m-1 d-InvoiceIndex-creditCardLog--jcb"></div>';
          break;
        case creditCardBrand['diners']:
          creditBrandIcon = '<div class="m-1 d-InvoiceIndex-creditCardLog--diners"></div>';
          break;
        case creditCardBrand['amex']:
          creditBrandIcon = '<div class="m-1 d-InvoiceIndex-creditCardLog--amex"></div>';
          break;
      }

      let creditCardCompany = document.getElementById("credit_card_company") as HTMLTableRowElement;
      if (!creditBrandIcon) {
        creditCardCompany.innerText = '取り扱い不可';
      } else {
        creditCardCompany.insertAdjacentHTML('afterbegin', creditBrandIcon);
      }
    }

    const creditCardNumber = getCreditCardNumber();
    const expire = getExpire();
    const securityCode = getSecurityCode();
    if (!validateCreditCardInformation(creditCardNumber, expire, securityCode)) {
      return;
    }

    mp.init(getShopId());
    mp.getToken({
      cardno: getCreditCardNumber(),
      expire: getExpire(),
      securitycode: getSecurityCode(),
      tokennumber: 1
    }, (gmoResponse) => {
      if (gmoResponse.resultCode !== '000') {
        alert('エラーが発生しました。時間を置いてやり直してもうまくいかない場合はお問い合わせください。');
        return;
      }
      const gmoTokenObject = gmoResponse.tokenObject;
      const cardNo: string = getCreditCardNumber();
      const expire: string = getExpire();

      setValue('js-cc-expire', expire);
      setValue('js-cc-token', gmoTokenObject.token[0]);

      (<HTMLFormElement>document.getElementById('js-create-cc')).submit();
    }
    );
    const modal: any = $('#js-creditCardModal');
    modal.modal('hide');

    let registerButton = document.getElementById('register_button') as HTMLInputElement;
    registerButton.disabled = false;

    let creditCardAddButton = document.getElementById('credit_card_add_button') as HTMLDivElement;
    creditCardAddButton.style.display = "none";

    // 登録したクレジットカード情報を料金プランの画面に表示
    let registeredCreditCard = document.getElementById('registered_credit_card') as HTMLTableElement;
    registeredCreditCard.style.display = "block";
    let creditRegisted = document.getElementById('credit_registed') as HTMLInputElement;
    if(creditRegisted) {
      creditRegisted.setAttribute('value', 'true');
    }

    let creditCardNo = document.getElementById("credit_card_no") as HTMLTableRowElement;
    const cha  = String(creditCardNumber);
    const visible = cha.slice(-4);
    const asterisk = '*'.repeat(12);
    creditCardNo.innerText = asterisk + visible;

    const creditBrand = findCreditBrand(cha);
    buildCreditIconDom(creditBrand)

    let creditCardExpire = document.getElementById("credit_card_expire") as HTMLTableRowElement;
    creditCardExpire.innerText = strIns(expire, 2, '/')
  });

  $('.js-cancel-credit-card').on('click', (e: JQuery.Event) => {
    e.preventDefault();

    const confirmInput = getInputElementById('input-cancel-confirm')
    const confirmInputValue = confirmInput.value;
    if (confirmInputValue !== 'YES') {
      // NOTE: 入力欄一つなので状態のリセットは不要。
      confirmInput.classList.add('is-invalid');
      return;
    }

    (<HTMLFormElement>document.getElementById('js-delete-cc')).submit();
  });

  $('.paid_btn').on('click', function () {
    if (confirm('決済を開始してよろしいですか？')) {
      var iid = $(this).data('iid');
      (<HTMLFormElement>document.getElementById('js-execute-invoice-' + iid)).submit()
    }
  });

  $('#credit_card_del_button').on('click', function () {
    let creditCardCompany = document.getElementById("credit_card_company") as HTMLTableRowElement;
    let creditCardNo = document.getElementById("credit_card_no") as HTMLTableRowElement;
    let creditCardExpire = document.getElementById("credit_card_expire") as HTMLTableRowElement;

    creditCardCompany.innerHTML = '';
    creditCardNo.innerHTML = '';
    creditCardExpire.innerHTML = '';
    
    const cardNumber = getInputElementById('input-creditcard-number') as HTMLInputElement;
    cardNumber.value = '';
    const expireMonth = getInputElementById('date_month') as HTMLInputElement;
    expireMonth.value = '';
    const expireYear = getInputElementById('date_year') as HTMLInputElement;
    expireYear.value = '';
    const securityCode = getInputElementById('input-security-code') as HTMLInputElement;
    securityCode.value = '';

    let registeredCreditCard = document.getElementById('registered_credit_card') as HTMLTableElement;
    registeredCreditCard.style.display = "none";
    let creditCardAddButton = document.getElementById('credit_card_add_button') as HTMLDivElement;
    creditCardAddButton.style.display = "block";
    let registerButton = document.getElementById('register_button') as HTMLInputElement;
    let requireCredit = registeredCreditCard.getAttribute('data-require-card-registration');
    if (requireCredit === 'true') {
      registerButton.disabled = true;
    } else {
      registerButton.disabled = false;
    }
    let creditRegisted = document.getElementById('credit_registed') as HTMLInputElement;
    if(creditRegisted) {
      creditRegisted.setAttribute('value', 'false');
    }
  });

});
